import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Cards } from '../components/cards/Cards'
import { Quote } from '../components/quote/Quote'

const IntroductiePage = () => {
  return (
    <Layout title="Even voorstellen" desc="Geen roze wolk? Je bent niet alleen!">
      <TextBanner>
        <HeadingMd h={2}>Even voorstellen</HeadingMd>
        <p>Na mijn studie Pedagogiek heb ik mijn hart verloren aan het werken met kinderen met een beperking. Te mogen leren van deze kinderen op het gebied van contact maken, kansen zien, denken in mogelijkheden heeft mij verrijkt als mens en professional. De stap om ouders mee te nemen in deze manier van denken was dan ook een logische stap. Het begeleiden van ouders, hen te erkennen in hun gevoel en steeds weer opnieuw met elkaar de schouders eronder te zetten om kwaliteit van leven te verhogen werd dan ook mijn missie!</p>
        <p>Tijdens mijn ingewikkelde zwangerschap van de tweeling waarbij er complicaties waren op groei en het risico op vroeggeboorte van de kinderen voelde ik de behoefte om mijn gevoelens en gedachten te delen met andere vrouwen en moeders. Op zoek naar hoop, erkenning en geruststelling. Ik wilde positief en sterk zijn en blijven, het goed doen maar werd door tegenslagen, angst en vermoeidheid steeds overvallen. Vooral gevoelens van onzekerheid, negatieve gedachtes over de moeilijke start van de tweeling en het gevoel van tekort schieten in het geven van aandacht en zorg aan mijn oudste zoon en mijn man maakten dat ik mij steeds kwetsbaarder voelde…</p>
        <Quote title="“Geen roze wolk?" text="Je bent niet alleen!”" />
        <p>Door mijn gedachten en gevoelens te delen met andere vrouwen en moeders bleek dat (bijna) alle moeders op hun eigen wijze verschillende moeilijkheden hebben ondervonden in hun transitie naar het moederschap. De een was in verwachting na het verlies van haar stil geboren baby, de ander schaamde zich omdat ze niet altijd blij en opgewekt was over haar nieuwe rol als moeder en weer een ander wist zich geen raad met het huilen van haar baby. Door met elkaar in gesprek te zijn, niet te oordelen maar elkaar te steunen, ervaringen uit te wisselen en gevoelens en gedachten te delen merkte ik dat ík niet alleen baat had bij deze gesprekken, dit ervaarden de andere moeders ook en gaven allemaal aan dat zij dit soort gesprekken hebben gemist ten tijde van hun eigen transitie naar het moederschap. De steun die ik heb ervaren doordat zij op momenten even “nabij” waren, ik mijn hart kon luchten, kon relativeren en in mijn kracht werd gezet is wat ik iedere (aanstaande) moeder gun.</p>
        <p>Mijn dank gaat dan ook uit naar alle vrouwen die ik heb ontmoet de afgelopen jaren; vriendinnen, passanten en professionals…door jullie heb ik mogen beleven dat de transitie naar het moederschap en het ontbreken van de roze wolk een antwoord heeft, Empowermom!</p>
        <p><strong>Jamie Laveber-Andriessen</strong></p>
      </TextBanner>

      <Cards />
    </Layout>
  )
}

export default IntroductiePage
